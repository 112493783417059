import React, { Fragment } from 'react';
// import {useTranslation} from "react-i18next";
import TopBar from '../../Components/TopBar';
import logo from '../../assets/images/logo.png';
import { Link } from 'react-router-dom';
import Footer from '../../Components/Footer';
import WelcomeMemorials from './welcomeMemorials';
import BulletPhrases from './bulletPhrases';
import { Helmet } from 'react-helmet';
import { Translate } from 'react-auto-translate/lib/commonjs';

export default function Welcome({children}) {
  // const {t} = useTranslation('common');

  return(
    <Fragment>
      <Helmet>
          <meta name="keywords" content='virtual memorials' />
          <meta name="description" content='Sign up for our virtual memorials website and join a supportive community where you can celebrate the lives of your loved ones who have passed away.' />
          <title>Virtual Memorials Website | Memkeepers</title>
      </Helmet>
      <TopBar/>
      <div className="theme-layout marble">
        <div className="welcome container">
          <div className="row merged">
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <div className="land-featurearea">
                <div className="land-meta">
                    <Fragment>
                      {/* <span className='welcomeMsg'>{t('welcome.welcome')}</span> <img src={logo} alt="logo" /> */}
                      <span className='welcomeMsg'><Translate>Welcome To</Translate></span> <img src={logo} alt="logo" />
                      <center className='quickLinks'>
                        {/* <Link to="/about" className='links'>{t('welcome.about-us')}</Link> */}
                        <Link to="/about" className='links'><Translate>About Us page</Translate></Link>
                        <Link to="/howitworks" className='links'><Translate>How It Works page</Translate></Link>
                      </center>
                    </Fragment>
                  <center>  
                  <div className='fbTitle'>
                      <h1><Translate>Virtual Memorials</Translate></h1>
                  </div>
                  <BulletPhrases />
                  </center>
                </div>  
              </div> 
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <div className="login-reg-bg">
                {children}
              </div>
              <div className='only-mobile'>
                <BulletPhrases mobileView={true} />
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-12'>
              <WelcomeMemorials />
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </Fragment>
  );
}