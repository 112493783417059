import React, { Fragment, useState, useEffect } from 'react';
import Footer from '../../Components/Footer';
import TopBar from '../../Components/TopBar';
import { useParams } from "react-router-dom";
import FeatureContent from './feature';
import { Helmet } from 'react-helmet';
import { FAQ_Helmet_Scripts } from '../../Util/common';

const Feature = () => {
    const [featureURL, setFeatureURL] = useState('');
    const params = useParams()

    const retrieveFAQScript = () => {
        for (let script = 0; script < FAQ_Helmet_Scripts.length; script++) {
            if(featureURL === FAQ_Helmet_Scripts[script].forURL) return <script type='application/ld+json'>{'{' + FAQ_Helmet_Scripts[script].script + '}'}</script>
        }
    }

    useEffect(() => {
        if(params) setFeatureURL(params.URL)
    }, [params])

    return (
        <Fragment>
        <Helmet>{retrieveFAQScript()}</Helmet>
        <TopBar />
            <div className="theme-layout marble">
                <div className="welcome container">
                    <div className='row'>
                        <div className='features col-lg-12'>
                            <FeatureContent featureURL={featureURL} />
                        </div>
                    </div>
                </div>
            </div>
        <Footer />
        </Fragment>
    );
}

export default Feature;
