import React, { useEffect, useState } from 'react';import Post from './post';
import SkeletonPost from './skeleton';

const FacebookPosts = ({ posts }) => {
    const [randomPost, setRandomPost] = useState(0);
    const [formattedPosts, setFormattedPosts] = useState([]);
    
    useEffect(() => {
        if(Object.keys(posts).length) setRandomPost(Math.floor(Math.random() * Object.keys(posts).length));
    }, [posts]);

    useEffect(() => {
        let copyPosts = Array.from(posts);
        if(randomPost !== 0) {
            copyPosts.splice(randomPost, 1);
            setFormattedPosts(copyPosts);
        }
    }, [randomPost])

    return (
        <div className='fbContainer'>
            <div className='fbPosts'>
                <div className='posts'>
                {
                    randomPost && randomPost !== 0 ? <Post post={posts[randomPost]}/> : null
                }
                {
                    formattedPosts && Object.keys(formattedPosts).length ?
                        formattedPosts.map(post => 
                            !post.disabled && <Post key={post.id} post={post} />  
                        )
                    :
                    <>
                        <SkeletonPost />
                        <SkeletonPost />
                    </>
                }
                </div>
            </div>
        </div>
    );
}

export default FacebookPosts;
