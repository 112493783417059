import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {useTranslation} from "react-i18next";
import { useUserContext } from '../../Contexts/userContext';
import { usePopupContext } from '../../Contexts/popupContext';

import flagEn from '../../assets/images/countries/flag-en.png';
import flagBg from '../../assets/images/countries/flag-bg.png';
import flagUs from '../../assets/images/countries/flag-us.png';
import flagEs from '../../assets/images/countries/flag-es.png';
import flagHn from '../../assets/images/countries/flag-hn.png';
import flagRo from '../../assets/images/countries/flag-ro.png';
import flagKo from '../../assets/images/countries/flag-ko.png';
import flagRu from '../../assets/images/countries/flag-ru.jpg';
import flagIt from '../../assets/images/countries/flag-it.jpg';
import flagFr from '../../assets/images/countries/flag-fr.jpg';
import flagDe from '../../assets/images/countries/flag-de.jpg';
import flagGr from '../../assets/images/countries/flag-gr.jpg';
import flagTr from '../../assets/images/countries/flag-tr.jpg';
import flagJp from '../../assets/images/countries/flag-jp.jpg';
import flagCn from '../../assets/images/countries/flag-cn.jpg';
import flagPl from '../../assets/images/countries/flag-pl.jpg';
import flagCz from '../../assets/images/countries/flag-cz.jpg';
import flagPo from '../../assets/images/countries/flag-po.jpg';
import flagCr from '../../assets/images/countries/flag-cr.jpg';
import flagNl from '../../assets/images/countries/flag-nl.jpg';
import flagIs from '../../assets/images/countries/flag-is.jpg';
import flagAr from '../../assets/images/countries/flag-ar.jpg';
import flagAf from '../../assets/images/countries/flag-af.png';
import flagIceLandic from '../../assets/images/countries/flag-is.png';
import flagSq from '../../assets/images/countries/flag-sq.png';
import flagAm from '../../assets/images/countries/flag-am.png';
import flagHy from '../../assets/images/countries/flag-hy.png';
import flagAs from '../../assets/images/countries/flag-as.png';
import flagAy from '../../assets/images/countries/flag-ay.png';
import flagAz from '../../assets/images/countries/flag-az.png';
import flagBm from '../../assets/images/countries/flag-bm.png';
import flagEu from '../../assets/images/countries/flag-eu.png';
import flagBe from '../../assets/images/countries/flag-be.png';
import flagBn from '../../assets/images/countries/flag-bn.png';
import flagBho from '../../assets/images/countries/flag-bho.png';
import flagBs from '../../assets/images/countries/flag-bs.png';
import flagCa from '../../assets/images/countries/flag-ca.png';
import flagCeb from '../../assets/images/countries/flag-ceb.png';
import flagCo from '../../assets/images/countries/flag-co.png';
import flagDa from '../../assets/images/countries/flag-da.png';
import flagDv from '../../assets/images/countries/flag-dv.png';
import flagEo from '../../assets/images/countries/flag-eo.png';
import flagEt from '../../assets/images/countries/flag-et.png';
import flagEe from '../../assets/images/countries/flag-ee.png';
import flagFi from '../../assets/images/countries/flag-fi.png';
import flagFy from '../../assets/images/countries/flag-fy.svg';
import flagGl from '../../assets/images/countries/flag-gl.png';
import flagKa from '../../assets/images/countries/flag-ka.png';
import flagGn from '../../assets/images/countries/flag-gn.png';
import flagGu from '../../assets/images/countries/flag-gu.png';
import flagHt from '../../assets/images/countries/flag-ht.png';
import flagHa from '../../assets/images/countries/flag-ha.png';
import flagHaw from '../../assets/images/countries/flag-haw.png';
import flagHe from '../../assets/images/countries/flag-he.png';
import flagHmn from '../../assets/images/countries/flag-hmn.png';
import flagHu from '../../assets/images/countries/flag-hu.png';
import flagId from '../../assets/images/countries/flag-id.png';
import flagGa from '../../assets/images/countries/flag-ga.png';
import flagKn from '../../assets/images/countries/flag-kn.png';
import flagKk from '../../assets/images/countries/flag-kk.png';
import flagKm from '../../assets/images/countries/flag-km.png';
import flagRw from '../../assets/images/countries/flag-rw.png';
import flagKri from '../../assets/images/countries/flag-kri.png';
import flagKu from '../../assets/images/countries/flag-ku.png';
import flagLo from '../../assets/images/countries/flag-lo.png';
import flagLa from '../../assets/images/countries/flag-la.png';
import flagLv from '../../assets/images/countries/flag-lv.svg';
import flagLn from '../../assets/images/countries/flag-ln.png';
import flagLt from '../../assets/images/countries/flag-lt.png';
import flagLg from '../../assets/images/countries/flag-lg.png';
import flagLb from '../../assets/images/countries/flag-lb.png';
import flagMk from '../../assets/images/countries/flag-mk.png';
import flagMg from '../../assets/images/countries/flag-mg.png';
import flagMs from '../../assets/images/countries/flag-ms.png';
import flagMt from '../../assets/images/countries/flag-mt.png';
import flagMi from '../../assets/images/countries/flag-mi.png';
import flagMn from '../../assets/images/countries/flag-mn.png';
import flagNe from '../../assets/images/countries/flag-ne.png';
import flagNo from '../../assets/images/countries/flag-no.png';
import flagOm from '../../assets/images/countries/flag-om.png';
import flagFa from '../../assets/images/countries/flag-fa.png';
import flagSm from '../../assets/images/countries/flag-sm.png';
import flagNso from '../../assets/images/countries/flag-nso.png';
import flagSr from '../../assets/images/countries/flag-sr.png';
import flagSt from '../../assets/images/countries/flag-st.png';
import flagSn from '../../assets/images/countries/flag-sn.png';
import flagSd from '../../assets/images/countries/flag-sd.png';
import flagSk from '../../assets/images/countries/flag-sk.png';
import flagSl from '../../assets/images/countries/flag-sl.png';
import flagSo from '../../assets/images/countries/flag-so.png';
import flagSw from '../../assets/images/countries/flag-sw.png';
import flagSv from '../../assets/images/countries/flag-sv.png';
import flagTg from '../../assets/images/countries/flag-tg.png';
import flagTa from '../../assets/images/countries/flag-ta.png';
import flagTh from '../../assets/images/countries/flag-th.png';
import flagTk from '../../assets/images/countries/flag-tk.png';
import flagUk from '../../assets/images/countries/flag-uk.png';
import flagUr from '../../assets/images/countries/flag-ur.svg';
import flagUg from '../../assets/images/countries/flag-ug.png';
import flagUz from '../../assets/images/countries/flag-uz.png';
import flagVi from '../../assets/images/countries/flag-vi.png';
import flagCy from '../../assets/images/countries/flag-cy.png';
import flagYo from '../../assets/images/countries/flag-yo.png';
import flagZu from '../../assets/images/countries/flag-zu.png';

export default function Languages() {
  const { handleError } = usePopupContext();
  const { updateUser } = useUserContext();
  const {i18n} = useTranslation('common');
  const [columns, setColumns] = useState([]);

  const setLanguage = lng => updateUser({Language: lng}).catch(handleError);

  const languages = {
    enus: {
      title: 'English (US)',
      image: flagUs
    },
    en: {
      title: 'English (UK)',
      image: flagEn
    },
    bg: {
      title: 'Bulgarian',
      image: flagBg
    },
    ru: {
      title: 'Russian',
      image: flagRu
    },
    it: {
      title: 'Italian',
      image: flagIt
    },
    fr: {
      title: 'French',
      image: flagFr
    },
    de: {
      title: 'German',
      image: flagDe
    },
    gr: {
      title: 'Greek',
      image: flagGr
    },
    es: {
      title: 'Spanish',
      image: flagEs
    },
    tr: {
      title: 'Turkish',
      image: flagTr
    },
    jp: {
      title: 'Japanese',
      image: flagJp
    },
    cn: {
      title: 'Chinese',
      image: flagCn
    },
    pl: {
      title: 'Polish',
      image: flagPl
    },
    cz: {
      title: 'Czech',
      image: flagCz
    },
    hn: {
      title: 'Hungarian',
      image: flagHn
    },
    ro: {
      title: 'Romanian',
      image: flagRo
    },
    po: {
      title: 'Portugal',
      image: flagPo
    },
    cr: {
      title: 'Croatian',
      image: flagCr
    },
    nl: {
      title: 'Netherlands',
      image: flagNl
    },
    // is: {
    //   title: 'Arab',
    //   image: flagIs
    // },
    ar: {
      title: 'Arabic',
      image: flagAr
    },
    ko: {
      title: 'Korean',
      image: flagKo
    },
    af: {
      title: 'Afrikaans',
      image: flagAf
    },
    sq: {
      title: 'Albanian',
      image: flagSq
    },
    am: {
      title: 'Amharic',
      image: flagAm
    },
    hy: {
      title: 'Armenian',
      image: flagHy
    },
    as: {
      title: 'Assamese',
      image: flagAs
    },
    ay: {
      title: 'Aymara',
      image: flagAy
    },
    az: {
      title: 'Azerbaijani',
      image: flagAz
    },
    bm: {
      title: 'Bambara',
      image: flagBm
    },
    eu: {
      title: 'Basque',
      image: flagEu
    },
    be: {
      title: 'Belarusion',
      image: flagBe
    },
    bn: {
      title: 'Bengali',
      image: flagBn
    },
    bho: {
      title: 'Bhojpuri',
      image: flagBho
    },
    bs: {
      title: 'Bosnian',
      image: flagBs
    },
    ca: {
      title: 'Catalon',
      image: flagCa
    },
    ceb: {
      title: 'Cebuano',
      image: flagCeb
    },
    co: {
      title: 'Corsican',
      image: flagCo
    },
    da: {
      title: 'Danish',
      image: flagDa
    },
    dv: {
      title: 'Dhivehi',
      image: flagDv
    },
    doi: {
      title: 'Dogri',
      image: flagAs
    },
    eo: {
      title: 'Esperanto',
      image: flagEo
    },
    et: {
      title: 'Estonian',
      image: flagEt
    },
    ee: {
      title: 'Ewe',
      image: flagEe
    },
    fi: {
      title: 'Finnish',
      image: flagFi
    },
    fy: {
      title: 'Frisian',
      image: flagFy
    },
    gl: {
      title: 'Galician',
      image: flagGl
    },
    ka: {
      title: 'Georgian',
      image: flagKa
    },
    gn: {
      title: 'Guarani',
      image: flagGn
    },
    gu: {
      title: 'Gujarati',
      image: flagGu
    },
    ht: {
      title: 'Haitian Creole',
      image: flagHt
    },
    ha: {
      title: 'Hausa',
      image: flagHa
    },
    haw: {
      title: 'Hawaiian',
      image: flagHaw
    },
    he: {
      title: 'Hebrew',
      image: flagHe
    },
    hi: {
      title: 'Hindi',
      image: flagAs
    },
    hmn: {
      title: 'Hmong',
      image: flagHmn
    },
    hu: {
      title: 'Hungarian',
      image: flagHu
    },
    is: {
      title: 'Icelandic',
      image: flagIceLandic
    },
    id: {
      title: 'Indonesian',
      image: flagId
    },
    ga: {
      title: 'Irish',
      image: flagGa
    },
    kn: {
      title: 'Kannada',
      image: flagKn
    },
    kk: {
      title: 'Kazakh',
      image: flagKk
    },
    km: {
      title: 'Khmer',
      image: flagKm
    },
    rw: {
      title: 'Kinyarwanda',
      image: flagRw
    },
    gom: {
      title: 'Konkani',
      image: flagAs
    },
    kri: {
      title: 'Krio',
      image: flagKri
    },
    ku: {
      title: 'Kurdish',
      image: flagKu
    },
    lo: {
      title: 'Lao',
      image: flagLo
    },
    la: {
      title: 'Latin',
      image: flagLa
    },
    lv: {
      title: 'Latvian',
      image: flagLv
    },
    ln: {
      title: 'Lingala',
      image: flagLn
    },
    lt: {
      title: 'Lithuanian',
      image: flagLt
    },
    lg: {
      title: 'Luganda',
      image: flagLg
    },
    lb: {
      title: 'Luxembourgish',
      image: flagLb
    },
    mk: {
      title: 'Macedonian',
      image: flagMk
    },
    mai: {
      title: 'Maithili',
      image: flagAs
    },
    mg: {
      title: 'Malagasy',
      image: flagMg
    },
    ms: {
      title: 'Malay',
      image: flagMs
    },
    ml: {
      title: 'Malayalam',
      image: flagAs
    },
    mt: {
      title: 'Maltese',
      image: flagMt
    },
    mi: {
      title: 'Maori',
      image: flagMi
    },
    mn: {
      title: 'Mongolian',
      image: flagMn
    },
    ne: {
      title: 'Nepali',
      image: flagNe
    },
    no: {
      title: 'Norwegian',
      image: flagNo
    },
    om: {
      title: 'Oromo',
      image: flagOm
    },
    fa: {
      title: 'Persian',
      image: flagFa
    },
    sm: {
      title: 'Samoan',
      image: flagSm
    },
    // sa: {
    //   title: 'Sanskrit',
    //   image: flagKo
    // },
    // gd: {
    //   title: 'Scots Gaelic',
    //   image: flagKo
    // },
    nso: {
      title: 'Sepedi',
      image: flagNso
    },
    sr: {
      title: 'Serbian',
      image: flagSr
    },
    st: {
      title: 'Sesotho',
      image: flagSt
    },
    sn: {
      title: 'Shona',
      image: flagSn
    },
    sd: {
      title: 'Sindhi',
      image: flagSd
    },
    sk: {
      title: 'Slovak',
      image: flagSk
    },
    sl: {
      title: 'Slovenian',
      image: flagSl
    },
    so: {
      title: 'Somali',
      image: flagSo
    },
    sw: {
      title: 'Swahili',
      image: flagSw
    },
    sv: {
      title: 'Swedish',
      image: flagSv
    },
    tg: {
      title: 'Tajik',
      image: flagTg
    },
    ta: {
      title: 'Tamil',
      image: flagTa
    },
    th: {
      title: 'Thai',
      image: flagTh
    },
    tk: {
      title: 'Turkmen',
      image: flagTk
    },
    uk: {
      title: 'Ukranian',
      image: flagUk
    },
    ur: {
      title: 'Urdu',
      image: flagUr
    },
    ug: {
      title: 'Uyghur',
      image: flagUg
    },
    uz: {
      title: 'Uzbek',
      image: flagUz
    },
    vi: {
      title: 'Vietnamese',
      image: flagVi
    },
    cy: {
      title: 'Welsh',
      image: flagCy
    },
    yo: {
      title: 'Yoruba',
      image: flagYo
    },
    zu: {
      title: 'Zulu',
      image: flagZu
    }
  };

  const getLanguageKey = (value) => {
    return Object.keys(languages).find(key => languages[key].title.toString().toLowerCase().includes(value.toLowerCase()));
  }

  const doSearch = (searched) => {
    let languagesCopy = languages;

    if(searched && searched.length) {
      let result = Object.values(languagesCopy).filter(item => item.title.toLowerCase().includes(searched.toLowerCase()));

      if (result && result.length) {
        let lKey = getLanguageKey(searched);
        sortLanguages({[lKey]: result[0]});
      }
      else sortLanguages({});
    } else sortLanguages({});
  }

  const searchLanguage = () => {
    return (
      <form key="searchForm" >
        <input
          key="searchInput"
          type="text" 
          placeholder="Search language"
          onChange={e => {
            doSearch(e.target.value);
          }} 
        />
        {/* <i className="fa fa-search" /> */}
      </form>
    )
  }

  const sortLanguages = (finded) => {
    let fromSearch = finded && Object.keys(finded).length ? true : false;

    if(fromSearch) {
      setColumns(Object.keys(finded).reduce((acc, v, i) => 
        i%2 === 0 ? [...acc, [v]] : [...acc.slice(0, acc.length-1), [...acc[acc.length-1], v]]
      ,[]))
    } else {
      setColumns(Object.keys(languages).reduce((acc, v, i) => 
        i%2 === 0 ? [...acc, [v]] : [...acc.slice(0, acc.length-1), [...acc[acc.length-1], v]]
      ,[]))
    }
  }

  useEffect(() => {
    sortLanguages();
  }, [])

  return (
    <Fragment>
      <Link to="/timeline" title="Click here to select language" data-ripple><img alt="Country flag" className='headerLanguage' src={languages[i18n.language]?.image}/></Link>
      <ul className="languages-dropdown">
        { searchLanguage() }
        {
          columns.map((l, idx) => (
            <li key={idx}>
              {
                columns.length % 2 === 0 ? 
                <>
                  <span title={languages[l[0]].title} onClick={() => setLanguage(l[0])}>
                    <img alt="Country flag" src={languages[l[0]].image}/> 
                    {languages[l[0]].title}
                  </span>
                  <span title={languages[l[1]].title} onClick={() => setLanguage(l[1])}>
                    <img alt="Country flag" src={languages[l[1]].image}/> 
                    {languages[l[1]].title}
                  </span>
                </>
                :
                <span title={languages[l[0]].title} onClick={() => setLanguage(l[0])}>
                  <img alt="Country flag" src={languages[l[0]].image}/> 
                  {languages[l[0]].title}
                </span>
              }
            </li>
          ))
        }
      </ul>
    </Fragment>
  )
};