import React from 'react';
import { getImageUrl, fbGetPostTextLength } from '../../Util/common';
import Photogrid from "react-facebook-photo-grid";
import logoM from '../../assets/images/logo-m.png';

const Post = ({ post }) => {

    function calculateTime(duration, originalDate) {
        if(duration.days === 0 && duration.hours === 0 && duration.minutes === 0) return 'now';
        if(duration.days === 0 && duration.hours === 0) return duration.minutes + 'm';
        if(duration.days === 0 && duration.hours <= 24 && duration.hours > 0) return duration.hours + 'h';
        if(duration.days > 0 && duration.days <= 10) return duration.days + 'd';
        if(duration.days > 10) return originalDate
    }

    return (
        <div className='post'>
        <div className='head'>
            <div className='pageLogo'>
                <img src={logoM} alt='logo' />
            </div>
            <div className='postInfo'>
                <span>{post.postedBy}</span>
                {/* <span>{calculateTime(Duration(new Date() - new Date(post.createdAt)), formatDateTime(post.createdAt))}</span> */}
            </div>
        </div>
        <div className='about'>
            <div dangerouslySetInnerHTML={{__html: post.text}}></div>
        </div>
        {
            post.uploads && post.uploads.length ?
            <div className='gallery'>
                <Photogrid
                    images={[getImageUrl(post.uploads[0].url)]}
                    width={445}
                    height={fbGetPostTextLength(post.text) > 9 ? 340 : 510}
                ></Photogrid>
            </div>
            :null
        }
        </div>
    );
}

export default Post;
